.text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  color: #000000;
  @media screen and (max-width: 430px) {
    font-size: 18px !important;
    text-align: center;
    margin-top: 32px !important;
  }
}
