$prime-blue: #2b4bf2;

.navigation {
  width: 1140px;
  height: 80px;
  margin: 0 auto;
  display: flex;
  border-bottom: 1px solid rgb(207, 207, 207);
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 430px) {
    width: 100%;
    max-width: 425px;
    height: 64px;
    justify-content: space-between;
    border-bottom: none;
  }
  .logo {
    min-width: 153px / 1440px * 100%;
    margin-left: auto;
    @media screen and (max-width: 430px) {
      margin: 20px 0 20px 14px;
      img {
        width: 146px;
        // height: 19px;
      }
    }
  }
  .link {
    display: flex;
    justify-content: space-around;
    width: 62%;
    @media screen and (max-width: 430px) {
      display: none;
    }
    a {
      color: black;
      line-height: 140%;
      font-size: 18px;
      cursor: pointer;
      transition: 0.3s;
    }
    a:hover {
      transition: 0.3s;
      color: $prime-blue;
    }
    a:active {
      color: #6a6a6a;
    }
    .arrow {
      margin-left: 10px;
      margin-bottom: 4px;
    }
  }
  .avatar {
    margin-right: 15px;
    height: 54px;
    width: 54px;
    @media screen and (max-width: 430px) {
      display: none;
    }
  }
}

.linkDropdown {
  display: inline-block;
  position: relative;
}

.linkDropdown a {
  padding: 10px;
}

.linkDropdown {
  .dropdown {
    position: absolute;
    top: 35px;
    right: -75px;
    color: black;
    background: white;
    height: 115px;
    width: 225px;
    padding: 22px 0 0 22px;
    border: 3px solid black;
    box-sizing: border-box;
    p {
      margin: 0;
    }
    a {
      padding: 0;
    }
  }
  Link {
    display: block;
  }
}

.dropdown__txt {
  margin-bottom: 22px !important;
}

.navigationDisabledLink {
  color: black !important;
}

.burger__item {
  display: none;
  margin-right: 16px;

  @media screen and (max-width: 430px) {
    display: flex;
  }
}

.burger__menu {
  display: none;
  @media screen and (max-width: 430px) {
    position: absolute;
    background: #ffffff;
    top: 61px;
    left: 0;
    width: 100%;
    height: calc(100vh - 61px);
    z-index: 5;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 30px;

    .hr {
      position: relative;
      border: 1.5px solid #d9d9d9;
      background-color: #d9d9d9;
      width: 100vw;
      left: -22px;
      max-width: 425px;
      margin: 0 auto;
      margin: 2.5px 20px 40px -10px;
    }

    a {
      color: black;
      font-size: 31.0645px;
      line-height: 140%;
      margin-bottom: 27px;
    }
    a:hover {
      color: $prime-blue;
      cursor: pointer;
    }
    a:active {
      color: #6a6a6a;
    }
  }
}
