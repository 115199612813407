$prime-blue: #2b4bf2;

.navigation {
  height: 80px;
  // width: 79%;
  width: 1140px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid rgba(66, 68, 72, 0.2);

  @media screen and (max-width: 430px) {
    width: 100%;
    max-width: 425px;
    height: auto;
    border-bottom: none;
  }

  @media screen and (max-width: 1160px) {
    width: 100%;
    max-width: 1160px;
    height: auto;
    border-bottom: none;
  }
}
.navigationWithoutLine {
  height: 80px;
  width: 1140px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 430px) {
    width: 100%;
    height: auto;
    max-width: 425px;
  }

  @media screen and (max-width: 1160px) {
    width: 100%;
    height: auto;
    max-width: 1160px;
  }
}
.logo {
  width: 153px / 1440px * 100%;

  @media screen and (max-width: 430px) {
    margin: 20px 0 20px 16px;
    svg {
      width: 142px;
      height: 19px;
    }
  }

  @media screen and (max-width: 1160px) {
    margin: 20px 0 20px 16px;
    svg {
      width: 142px;
      height: 19px;
    }
  }
}

.burger__item {
  display: none;
  margin-right: 16px;

  @media screen and (max-width: 430px) {
    display: flex;
  }

  @media screen and (max-width: 1160px) {
    display: flex;
  }
}

.link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;

  @media screen and (max-width: 430px) {
    display: none;
  }

  @media screen and (max-width: 1160px) {
    display: none;
  }

  // width: 26%;
  a {
    color: black;
    line-height: 140%;
    font-size: 18px;
  }
  a:hover {
    color: $prime-blue;
    cursor: pointer;
  }
  a:active {
    color: #6a6a6a;
  }
}

.burger__menu {
  display: none;
  @media screen and (max-width: 430px) {
    position: absolute;
    background: #ffffff;
    top: 61px;
    left: 0;
    width: 100%;
    height: calc(100vh - 61px);
    z-index: 5;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 30px;

    .hr {
      position: relative;
      border: 1.5px solid #d9d9d9;
      background-color: #d9d9d9;
      // width: calc(100vw - 50px);
      width: 100vw;
      left: -22px;
      max-width: 425px;
      margin: 0 auto;
      margin: 2.5px 20px 40px -10px;
    }

    a {
      color: black;
      font-size: 31.0645px;
      line-height: 140%;
      margin-bottom: 27px;
    }
    a:hover {
      color: $prime-blue;
      cursor: pointer;
    }
    a:active {
      color: #6a6a6a;
    }
  }

  @media screen and (max-width: 1160px) {
    position: absolute;
    background: #ffffff;
    top: 61px;
    left: 0;
    width: 100%;
    height: calc(100vh - 61px);
    z-index: 5;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 30px;

    .hr {
      position: relative;
      border: 1.99px solid #d9d9d9;
      background-color: #d9d9d9;
      // width: calc(100vw - 50px);
      width: 100vw;
      left: -22px;
      max-width: 1160px;
      margin: 0 auto;
      margin: 2.5px 20px 40px -10px;
    }

    a {
      color: black;
      font-size: 31.0645px;
      line-height: 140%;
      margin-bottom: 27px;
    }
    a:hover {
      color: $prime-blue;
      cursor: pointer;
    }
    a:active {
      color: #6a6a6a;
    }
  }
}
