.blockVideo {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.startVideo {
  width: 100% !important;
  min-height: 430px;
  @media screen and (max-width: 1200px) {
    min-height: 300px;
  }
  @media screen and (max-width: 1024px) {
    min-height: 578px;
  }
  @media screen and (max-width: 850px) {
    min-height: 482px;
  }
  @media screen and (max-width: 750px) {
    min-height: 424px;
  }
  @media screen and (max-width: 640px) {
    min-height: 362px;
  }
  @media screen and (max-width: 520px) {
    min-height: 294px;
  }
  @media screen and (max-width: 430px) {
    width: 100%;
    max-width: 425px;
    min-height: 260px;
    margin: 0 auto;
  }
}

.video__wrapper {
  min-height: 430px;
  min-width: 650px;
  @media screen and (max-width: 1200px) {
    width: 100%;
    min-width: 480px;
    max-width: 480px;
    min-height: 320px;
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
    max-width: 1024px;
    height: auto;
  }
  @media screen and (max-width: 430px) {
    width: 100%;
    max-width: 425px;
    min-height: 260px !important;
  }
}
.text__btn__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2 {
    font-family: "Roboto Slab";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 100%;
    letter-spacing: -0.02em;

    color: #ffffff;
  }
}
.btnPlay {
  position: absolute;
  bottom: 10%;

  @media screen and (max-width: 1214px) {
    & {
      font-size: 0;
    }

    & > .btnPlayImg {
      margin-left: 0;
    }
  }

  @media screen and (max-width: 320px) {
    & > .btnPlayImg {
      margin-left: -5px;
    }
  }
}

.btnPlayImg {
  margin-top: -2px;
  margin-left: 10px;
  vertical-align: middle;
}
