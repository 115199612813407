.wrapper {
  min-height: 350px;
  margin-top: 62px;
  margin-bottom: 32px;
  @media screen and (max-width: 948px) {
    width: 100%;
    max-width: 554px;
    margin: 0 auto;
    min-height: 20px;
  }
  @media screen and (max-width: 600px) {
    max-width: 360px;
  }
  h1 {
    font-size: 64px;
    text-align: left;
    line-height: 100%;
    @media screen and (max-width: 948px) {
      font-size: 46px;
      margin-top: 18px;
    }
    @media screen and (max-width: 600px) {
      font-size: 38px;
      margin-top: 18px;
    }
  }
  p {
    margin: 0;
    margin-top: 36px;
    text-align: left;
    font-size: 18px;
    font-family: "Roboto", sans-serif !important;
    line-height: 140%;
    width: 92%;
    @media screen and (max-width: 600px) {
      font-size: 16px;
      margin-top: 28px;
    }
  }

  .linkM {
    display: none;
    @media screen and (max-width: 948px) {
      display: block;
      margin-top: 18px;
    }
  }
}

.imgDetailInfo {
  margin: 0 auto;
  margin-top: 35px;
  display: block;
  width: 200px;
  height: 200px;
  min-height: 200px;
  border-radius: 50%;
}

.imgM {
  display: none;
  @media screen and (max-width: 948px) {
    display: block;
    margin-bottom: 32px;
  }
}
