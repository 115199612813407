.react-player__preview {
  position: relative;
  min-height: 432px !important;
  @media screen and (max-width: 1200px) {
    min-height: 300px !important;
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
    max-width: 1024px !important;
    min-height: 578px !important;
    height: auto !important;
  }

  @media screen and (max-width: 850px) {
    min-height: 482px !important;
  }

  @media screen and (max-width: 750px) {
    min-height: 424px !important;
  }

  @media screen and (max-width: 640px) {
    min-height: 362px !important;
  }

  @media screen and (max-width: 520px) {
    min-height: 294px !important;
  }

  @media screen and (max-width: 430px) {
    width: 100%;
    max-width: 425px !important;
    min-height: 260px !important;
  }
}

video {
  height: auto !important;
  width: 100%;
  max-width: 650px;
  @media screen and (max-width: 1200px) {
    min-height: 300px;
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
    max-width: 1024px;
    min-height: 578px;
    height: auto;
  }
  @media screen and (max-width: 850px) {
    min-height: 482px;
  }
  @media screen and (max-width: 750px) {
    min-height: 424px;
  }
  @media screen and (max-width: 640px) {
    min-height: 362px;
  }
  @media screen and (max-width: 520px) {
    min-height: 294px;
  }
  @media screen and (max-width: 430px) {
    width: 100%;
    max-width: 425px;
    margin: 0;
    padding: 0;
    min-height: 260px;
  }
}

.video_carousel_wrapper {
  .wrapper {
    width: 100%;
    font-size: 30px;
    // .carousel {
    //   max-height: 826px;
    //   @media screen and (max-width: 2000px) {
    //     & {
    //       max-height: 960px;
    //     }
    //   }
    //   @media screen and (max-width: 1580px) {
    //     & {
    //       max-height: 898px;
    //     }
    //   }
    //   @media screen and (max-width: 1480px) {
    //     & {
    //       max-height: 868px;
    //     }
    //   }
    //   @media screen and (max-width: 1440px) {
    //     & {
    //       // max-height: 712px;
    //       max-height: 830px;
    //     }
    //   }
    //   @media screen and (max-width: 1366px) {
    //     & {
    //       max-height: 803px;
    //     }
    //   }
    //   @media screen and (max-width: 1280px) {
    //     & {
    //       max-height: 750px;
    //     }
    //   }
    //   @media screen and (max-width: 1214px) {
    //     & {
    //       max-height: 686px;
    //     }
    //   }
    //   @media screen and (max-width: 1120px) {
    //     & {
    //       max-height: 640px;
    //     }
    //   }
    //   @media screen and (max-width: 1024px) {
    //     & {
    //       max-height: 592px;
    //     }
    //   }
    //   @media screen and (max-width: 801px) {
    //     & {
    //       max-height: 480px;
    //     }
    //   }
    //   @media screen and (max-width: 512px) {
    //     & {
    //       max-height: 338px;
    //     }
    //   }
    //   @media screen and (max-width: 361px) {
    //     & {
    //       max-height: 230px;
    //     }
    //   }
    // }
  }

  .slide {
    max-height: 100%;
    padding: 0px;
  }

  .control-arrow {
    height: 100% !important;
    width: 50px !important;
  }
}
