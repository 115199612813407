.mainBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 430px) {
    margin: 0 auto;
    width: 100%;
    max-width: 360px;
    text-align: center;
  }
}
