.footer {
  margin: 0 auto;
  bottom: 0px;
  display: flex;
  justify-content: space-between;
  width: 1140px;
  height: 80px;
  border-top: 1px solid #d9dada;
  padding-top: 10px;
  @media screen and (max-width: 430px) {
    width: 100%;
    max-width: 425px;
    border-top: 3px solid #d9d9d9;
  }
  span {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: #424448;
    @media screen and (max-width: 430px) {
      margin-left: 16px;
    }
  }
  a {
    text-decoration: none;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: #424448;
    margin-left: 32px;
  }
  .second__link {
    @media screen and (max-width: 430px) {
      margin-right: 16px;
    }
  }
}
