$prime-blue: #2b4bf2;

.LogoBar {
  display: flex;
  align-items: center;
  justify-content: start;
  height: 80px;
  width: 1140px;
  border-bottom: 1px solid #cfcfcf;
  margin: 0 auto;
  @media screen and (max-width: 430px) {
    width: 100%;
    max-width: 425px;
    border-bottom: 3px solid #cfcfcf;
    height: 58px;
    justify-content: space-between;

    .burger__item {
      margin-right: 16px;
    }
    img {
      padding-left: 16px;
      width: 142px;
    }
  }
  div {
    cursor: pointer;
  }
}

.burger__item {
  display: none;
  @media screen and (max-width: 430px) {
    display: flex;
  }
}

.burger__menu {
  display: none;
  @media screen and (max-width: 430px) {
    position: absolute;
    background: #ffffff;
    top: 67px;
    left: 0;
    width: 100%;
    height: calc(100vh - 67px);
    z-index: 5;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 30px;
    margin-top: 37px;

    .hr {
      position: relative;
      border: 1.5px solid #d9d9d9;
      background-color: #d9d9d9;
      // width: calc(100vw - 50px);
      width: 100vw;
      left: -22px;
      max-width: 425px;
      margin: 0 auto;
      margin: 2.5px 20px 40px -10px;
    }

    a {
      color: black;
      font-size: 31.0645px;
      line-height: 140%;
      margin-bottom: 27px;
    }
    a:hover {
      color: $prime-blue;
      cursor: pointer;
    }
    a:active {
      color: #6a6a6a;
    }
  }
}
