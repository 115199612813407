$dark-gray-color: #424448;
$light-gray-color: #d5d5d5;
$prime-blue: #2b4bf2;

.navigation {
  background-color: $dark-gray-color;
  height: 80px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 430px) {
    width: 100%;
    max-width: 425px;
    height: 64px;
    justify-content: space-between;
    border-bottom: none;
  }
  .logo {
    min-width: 153px / 1440px * 100%;
    margin-right: 33px;
    @media screen and (max-width: 430px) {
      margin: 20px 0 20px 14px;
      img {
        width: 146px;
        // height: 19px;
      }
    }
  }
  .nav__wrapper {
    margin: 0 auto;
    width: 1140px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .linkSessions {
    display: flex;
    justify-content: space-around;
    width: 860px;
    align-items: center;
    a {
      color: black;
      line-height: 140%;
      font-size: 18px;
      cursor: pointer;
      transition: 0.3s;
    }
    a:hover {
      color: white;
      transition: 0.3s;
    }
    a:active {
      color: #6a6a6a;
    }
    .arrow {
      margin-left: 5px;
      margin-bottom: 4px;
    }
  }
  .log__out__link {
    color: $light-gray-color !important;
    &:hover {
      color: white !important;
    }
    &:active {
      color: #6a6a6a !important;
    }
  }
  .link {
    display: flex;
    justify-content: space-between;
    width: 970px;
    align-items: center;
    @media screen and (max-width: 430px) {
      display: none;
    }
    a {
      color: $light-gray-color;
      font-weight: normal;
      line-height: 140%;
      font-size: 18px;
      cursor: pointer;
    }
    a:hover {
      color: white;
    }
    a:active {
      color: #6a6a6a;
    }
    .arrow {
      margin-left: 5px;
      margin-bottom: 4px;
    }
  }

  .avatar {
    width: 54px;
    height: 54px;
    @media screen and (max-width: 430px) {
      display: none;
    }
  }
}

.burger__item {
  display: none;
  margin-right: 16px;

  @media screen and (max-width: 430px) {
    display: flex;
  }
}

.burger__menu {
  display: none;
  @media screen and (max-width: 430px) {
    position: absolute;
    background: #424448;
    top: 61px;
    left: 0;
    width: 100%;
    height: calc(100vh - 61px);
    z-index: 5;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 30px;
    border-top: 3px solid #d9d9d9;
    padding-top: 30px;

    a {
      color: #d9d9d9;
      font-size: 31.0645px;
      line-height: 140%;
      margin-bottom: 22px;
    }
    a:hover {
      color: $prime-blue;
      cursor: pointer;
    }
    a:active {
      color: #6a6a6a;
    }
  }
}
