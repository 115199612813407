.carousel_wrapper {
  .wrapper {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)),
      url("./Man.jpg") no-repeat center center;
    background-size: cover;
    height: auto;
    width: 100%;
    font-size: 30px;
    @media screen and (max-width: 430px) {
      width: 100%;
      max-width: 425px;
      height: 260px;
    }

    .bigText {
      margin: 0 auto;
      width: 1140px;
      font-size: 36px;
      margin-bottom: 32px;
      @media screen and (max-width: 1264px) {
        font-size: 23px;
        width: 100%;
        max-width: 620px;
        padding-top: 80px !important;
      }
      @media screen and (max-width: 768px) {
        font-size: 16px;
        padding-top: 20px !important;
        width: 100%;
        max-width: 430px;
      }
      @media screen and (max-width: 568px) {
        font-size: 14px;
        padding-top: 20px !important;
        width: 100%;
        max-width: 330px;
      }
      @media screen and (max-width: 430px) {
        padding-top: 43px !important;
        margin-bottom: 0;
        width: 100%;
        max-width: 309px;
        font-size: 9.75px;
        line-height: 130%;
      }
    }
    .slides__el {
      margin: 0 auto;
      width: 1140px;
      @media screen and (max-width: 1264px) {
        width: 100%;
        max-width: 620px;
        padding-top: 176px !important;
        h1 {
          font-size: 36px;
        }
      }
      @media screen and (max-width: 768px) {
        padding-top: 98px !important;
        h1 {
          font-size: 22px;
        }
      }
      @media screen and (max-width: 430px) {
        width: 100%;
        max-width: 309px;
        padding-top: 102px !important;
        h1 {
          margin-bottom: 0;
          line-height: 130%;
          font-size: 9.75px;
        }
      }
      h1 {
        letter-spacing: -0.02em;
        line-height: 100%;
      }
    }
    .name__block {
      font-family: "Roboto Slab";
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 110%;
      @media screen and (max-width: 768px) {
        font-size: 20px;
      }
      @media screen and (max-width: 430px) {
        font-size: 9.75px;
        line-height: 110%;
      }
    }
    .carousel {
      margin-top: 150px;
      height: 960px;
    }
    .slide {
      height: 960px;
      padding: 20px 60px 20px 60px;
    }
    .control-arrow {
      height: 100% !important;
      width: 50px !important;
    }
  }
}
