.wrapperRight {
  width: 55%;
  margin-bottom: 40px;
  @media screen and (max-width: 948px) {
    width: 100%;
    max-width: 554px;
    margin: 0 auto;
  }
  @media screen and (max-width: 600px) {
    max-width: 360px;
  }

  .DescC {
    @media screen and (max-width: 948px) {
      display: none;
    }
  }
}

.gridBlock {
  display: grid;
  margin: 0 5.5%;
}

.container {
  h3 {
    margin-left: 5.5%;
    line-height: 130px;
  }
  display: flex;
  width: 1152px;
  margin: 0 auto;
  @media screen and (max-width: 1160px) {
    width: 100%;
    max-width: 1160px;
    overflow-x: hidden;
    border-top: 3px solid #d9d9d9;
  }
  @media screen and (max-width: 948px) {
    width: 100%;
    max-width: 948px;
    flex-direction: column;
    overflow-x: hidden;
    margin-bottom: 50px;
  }
  @media screen and (max-width: 430px) {
    width: 100%;
    max-width: 425px;
  }

  .DescM {
    display: none;
    @media screen and (max-width: 948px) {
      display: flex;
    }
  }
}

.wrapperLeft {
  width: 18%;
  height: 450px;
  margin-right: 90px;
  @media screen and (max-width: 1216px) {
    width: 20%;
  }
  @media screen and (max-width: 948px) {
    width: 100%;
    max-width: 201px;
    margin: 0 auto;
    height: auto;
    margin-bottom: 50px;
  }
}

.emptyBlock {
  width: 25%;
}

.headingTimeSlots {
  font-size: 22px;
  line-height: 130%;
  margin-left: 40px;
  @media screen and (max-width: 600px) {
    font-size: 20px;
    margin-left: 0;
  }
}

.hr {
  display: none;
  @media screen and (max-width: 430px) {
    display: block;
    border: 1.5px solid #d9d9d9;
    width: calc(100vw - 50px);
    margin: 0 auto;
    background-color: #d9d9d9;
    margin-top: 50px;
  }
}
