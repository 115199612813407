.feedbackBlock {
  display: flex;
  width: 659px;
  margin-left: -55px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 948px) {
    width: 100%;
    max-width: 554px;
    margin-left: 0;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    max-width: 425px;
    margin-left: 0;
    margin-top: 33px;
  }
  p {
    margin: 16px 0 16px 0;
  }
  button {
    background-color: white;
  }
}

.wrapper {
  border: 2px solid #000000;
  border-radius: 20px;
  margin: 0 auto;
  padding: 32px;
  width: 555px;
  box-sizing: border-box;
  min-height: 234px;
  @media screen and (max-width: 948px) {
    width: 100%;
    max-width: 436px;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    max-width: 270px;
  }
}

.wrapper__txt {
  min-height: 69px;
}

.textFeedback {
  width: 620px;
  display: flex;
  p {
    margin: auto 0 auto 8px;
  }
}

.imgFeedback {
  width: 32px;
  height: 32px;
}

.leftArroy {
  margin-right: 32px;
  @media screen and (max-width: 600px) {
    margin-right: 18px;
  }
}

.rightArroy {
  margin-left: 32px;
  @media screen and (max-width: 600px) {
    margin-left: 18px;
  }
}

.feedback__stars {
  span {
    margin-right: 10px;
  }
}
