.wrapperTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  width: 555px;
  @media screen and (max-width: 948px) {
    width: 100%;
    max-width: 620px;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    max-width: 360px;
    flex-direction: column;
  }
  h3 {
    font-size: 36px !important;
    font-weight: bold !important;
    line-height: 110% !important;
    font-family: "Roboto Slab", serif !important;
    margin: 0 !important;
    padding: 0;
    margin-right: 26%;
    @media screen and (max-width: 600px) {
      font-size: 28px !important;
    }
  }
  p {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    line-height: 150%;
    margin: 0;
    margin-top: 17px;
  }
  .timeZone {
    font-family: "Roboto", sans-serif !important;
    font-size: 18px;
    width: 280px;
    height: 59px;
    margin-top: 7px;
    border: 2px solid black;
    border-radius: 4px;
    line-height: 150%;
    background-color: white;
    @media screen and (max-width: 600px) {
      height: 48px;
      margin-top: 18px;
      font-size: 16px;
    }
    option {
      font-size: 18px;
      font-family: "Roboto", sans-serif !important;
      @media screen and (max-width: 600px) {
        font-size: 16px;
      }
    }
  }
}
