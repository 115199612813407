.errorInput {
  border: 2px solid #D43333 !important;
}

.select {
  width: 100%;
}

select {
  width: 100%;
  margin-top: 8px;
  border: 2px solid black;
  border-radius: 4px;
  height: 60px;
  font-size: 22px;
  font-family: "Roboto", sans-serif !important;
  color: black;
}

option {
  font-size: 22px;
  font-family: "Roboto", sans-serif !important;
}