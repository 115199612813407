.carousel {
  /* max-height: 960px; */
  height: 100%;
}

.slide{
  /* max-height: 960px; */
  height: 100%;
  padding: 20px 60px 20px 60px;
}

.control-arrow{
  height: 100% !important;
  width: 50px !important;
}

@media screen and (max-width: 1264px) {
  .slide {
    min-height: 680px;
  }
}

@media screen and (max-width: 1116px) {
  .slide {
    min-height: 600px;
  }
}

@media screen and (max-width: 768px) {
  .slide {
    min-height: 420px;
  }
}

@media screen and (max-width: 568px) {
  .slide {
    min-height: 368px;
  }
}