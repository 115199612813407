.slot {
  border-radius: 9px;
  height: 48px;
  margin-top: 10px;
  font-size: 18px;
  line-height: 48px;
  text-align: center;
  background-color: #f6f6f6;
  @media screen and (max-width: 430px) {
    font-size: 16px;
  }
}
