$prime-blue: #2b4bf2;

.navigation {
  height: 80px;
  width: 1140px;
  margin: 0 auto;
  display: flex;
  border-bottom: 1px solid rgb(207, 207, 207);
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 430px) {
    width: 100%;
    max-width: 425px;
    height: 64px;
    justify-content: space-between;
    border-bottom: none;
  }
  form {
    display: inline-block;
    vertical-align: top;
    position: relative;
  }
  input {
    background-color: #f9f9f9 !important;
    outline: none;
    border: none;
    display: block;
    width: 224px;
    height: 56px !important;
    background: #fff;
    border-radius: 5px;
  }
  button {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 9;
    outline: none;
    border: none;
    display: inline-block;
    padding: 10px 25px;
    border-radius: 25px;
    cursor: pointer;
    width: 10px;
    background-color: #f9f9f9 !important;
    img {
      position: absolute;
      top: 12px;
      left: 5px;
      height: 30px;
      width: 30px;
    }
  }

  .logo {
    min-width: 153px / 1440px * 100%;
    margin-left: auto;
    @media screen and (max-width: 430px) {
      margin: 20px 0 20px 14px;
      img {
        width: 146px;
        // height: 19px;
      }
    }
  }
  .linkSessions {
    display: flex;
    justify-content: space-around;
    width: 860px;
    align-items: center;
    // @media screen and (max-width: 430px) {
    //   display: none;
    // }
    a {
      color: black;
      line-height: 140%;
      font-size: 18px;
      cursor: pointer;
      transition: 0.3s;
    }
    a:hover {
      color: $prime-blue;
      transition: 0.3s;
    }
    a:active {
      color: #6a6a6a;
    }
    .arrow {
      margin-left: 5px;
      margin-bottom: 4px;
    }
  }

  .link {
    display: flex;
    justify-content: space-around;
    width: 705px;
    align-items: center;
    @media screen and (max-width: 430px) {
      display: none;
    }
    a {
      color: black;
      line-height: 140%;
      font-size: 18px;
      cursor: pointer;
    }
    a:hover {
      color: $prime-blue;
    }
    a:active {
      color: #6a6a6a;
    }
    .arrow {
      margin-left: 5px;
      margin-bottom: 4px;
    }
  }

  .avatar {
    margin-right: 15px;
    width: 54px;
    height: 54px;
    @media screen and (max-width: 430px) {
      display: none;
    }
  }
}

.searchData {
  position: relative;
  img {
    position: absolute;
    width: 25px;
    height: 25px;
    right: 20px;
    top: 15px;
  }
  input {
    cursor: pointer;
  }
}

.navigationDisabledLink {
  color: black !important;
}

.burger__item {
  display: none;
  margin-right: 16px;

  @media screen and (max-width: 430px) {
    display: flex;
  }
}

.burger__menu {
  display: none;
  @media screen and (max-width: 430px) {
    position: absolute;
    background: #ffffff;
    top: 61px;
    left: 0;
    width: 100%;
    height: calc(100vh - 61px);
    z-index: 5;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 30px;

    .hr {
      position: relative;
      border: 1.5px solid #d9d9d9;
      background-color: #d9d9d9;
      width: 100vw;
      left: -22px;
      max-width: 425px;
      margin: 0 auto;
      margin: 2.5px 20px 40px -10px;
    }

    a {
      color: black;
      font-size: 31.0645px;
      line-height: 140%;
      margin-bottom: 27px;
    }
    a:hover {
      color: $prime-blue;
      cursor: pointer;
    }
    a:active {
      color: #6a6a6a;
    }
  }
}
