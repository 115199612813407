.button {
  width: 200px;
  height: 48px;
  background: #2b4bf2;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: white;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  transition-duration: 0.2s;

  &:disabled {
    background: #d5d5d5;
    cursor: default;
  }

  &:disabled:hover {
    transform: translate(0, 0) !important;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 1) !important;
  }
  &:hover {
    transform: translate(0, -4px);
    box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 1);
  }
  &:active {
    transform: translate(0, 4px);
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 1);
  }
}
