.avatarContainer {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  .loadingImg {
    width: inherit;
    height: inherit;
    border-radius: inherit;
    background: linear-gradient(
      -60deg,
      rgba(215, 215, 215, 1) 0%,
      rgba(215, 215, 215, 1) 40%,
      rgba(255, 255, 255, 1) 50%,
      rgba(215, 215, 215, 1) 60%,
      rgba(215, 215, 215, 1) 100%
    );
    background-size: 100% 400%;
    animation-name: gradient;
    -webkit-animation-name: gradient;
    animation-duration: 1300ms;
    -webkit-animation-duration: 1300ms;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: gradient;
    -moz-animation-duration: 1300ms;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: gradient;
    -ms-animation-duration: 1300ms;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
  }
  .emtyAvatar {
    width: inherit;
    height: inherit;
    border-radius: inherit;
  }
  img {
    width: inherit;
    height: inherit;
    border-radius: inherit;
  }
}

@keyframes gradient {
  0% {
    background-position: 100% 100%;
  }
  20% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 100% 0%;
  }
}

@-moz-keyframes gradient {
  0% {
    background-position: 100% 100%;
  }
  20% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 100% 0%;
  }
}
@-webkit-keyframes gradient {
  0% {
    background-position: 100% 100%;
  }
  20% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 100% 0%;
  }
}