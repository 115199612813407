body {
  margin: 0;
  padding: 0;
}

.wrapper {
  width: 358px;
  margin: 0 auto;
}

ul {
  /* margin: 0; */
  /* padding: 0; */
}

h1 {
  margin-top: 52px;
  margin-bottom: 0px;
  text-align: center;
  font-size: 36px;
  font-weight: bold;
  font-family: "Roboto Slab", serif;
}

li {
  /* list-style: none; */
}

input {
  width: 100%;
  font-size: 18px;
  font-family: "Roboto", sans-serif !important;
  text-indent: 10px;
  padding: 0;
  height: 54px;
  width: 354px;
  border-radius: 4px;
}

button[disabled],
button:disabled {
  cursor: default !important;
  background-color: #d5d5d5;
}

button {
  padding: 0;
  background-color: #2B4BF2;
  color: #ffffff;
  width: 100%;
  border-radius: 4px;
  height: 48px;
  border: none;
  font-size: 18px;
  font-family: "Roboto", sans-serif !important;
  font-weight: bold;
}

/* input {
  height: 56px;
  border-radius: 4px;
} */

a {
  text-decoration: none;
  font-size: 18px;
  font-family: "Roboto", sans-serif !important;
}

.link {
  text-align: center;
  margin-top: 10px;
}

input:focus {
  outline: none !important;
  border: 2px solid #2b4bf2;
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: start;
  height: 80px;
  width: 1140px;
  border-bottom: 1px solid #cfcfcf;
  margin: 0 auto;
}

.mail {
  margin-top: 60px;
}

.password {
  margin-top: 30px;
}

.singUpLinkContainer {
  margin-top: 32px;
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-family: "Roboto", sans-serif !important;
}

.singUpLink {
  margin-left: 4px;
}
