$prime-blue: #2b4bf2;
$red: #d43333;
$border-black: black;

.studentReg {
  font-family: "Roboto Slab", serif;
  margin: 0 auto;
  margin-bottom: 120px;
  @media screen and (max-width: 430px) {
    margin-bottom: 0;
    overflow-x: hidden;
  }
  h1 {
    font-size: 64px;
    font-weight: bold;
    @media screen and (max-width: 430px) {
      font-size: 32px;
      margin-top: 34px;
      text-align: left;
    }
  }
  label {
    font-size: 20px;
    font-weight: bold;
    font-family: "Roboto", sans-serif !important;
    @media screen and (max-width: 430px) {
      font-size: 18px;
    }
  }
  input {
    height: 56px;
    border: 2px solid $border-black;
    border-radius: 4px;
    margin-top: 8px;
    font-size: 18px;
    text-indent: 10px;
    @media screen and (max-width: 430px) {
      width: 100%;
      max-width: 321px;
      height: 48px !important;
    }
  }
}

.studentReg input:focus {
  outline: none !important;
  border: 2px solid $prime-blue;
}

.form {
  // width: 750px / 1440px * 100%;
  width: 750px;
  margin: 0 auto;
  @media screen and (max-width: 430px) {
    width: 100%;
    max-width: 325px;

    button {
      width: 164px;
      height: 48px;
    }
  }
  h2 {
    font-size: 36px;
    @media screen and (max-width: 430px) {
      font-size: 24px;
      margin-top: 37px;
    }
  }
}

.blocksmallInput {
  width: 358px / 750px * 100%;
  min-height: 140px;
  @media screen and (max-width: 430px) {
    width: 100vw;
    display: flex;
    flex-direction: column;
    min-height: 100px;

    input {
      margin-top: 8px !important;
      margin-bottom: 17px !important;
    }
  }
  input {
    width: 100%;
  }
}

.wrapperName,
.wrapperPassword {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 430px) {
    flex-direction: column;
    margin-bottom: 0;
  }
}

.wrapperDate {
  display: flex;
  justify-content: space-between;
  margin-bottom: 45px;
  width: 100%;
}

.blockBirth {
  width: 36%;
  min-height: 120px;
  @media screen and (max-width: 430px) {
    width: 112%;
    min-height: 100px;
  }
}

.blockZone {
  width: 416px / 750px * 100%;
  @media screen and (max-width: 430px) {
    width: 100%;
  }
  select {
    margin-top: 7px;
    border: 2px solid $border-black;
    border-radius: 4px;
    height: 59px;
    font-size: 22px;
    font-family: "Roboto", sans-serif !important;
    @media screen and (max-width: 430px) {
      width: 110%;
      max-width: 325px;
      height: 54px;
      font-size: 18px;
    }
  }
  option {
    font-size: 22px;
    font-family: "Roboto", sans-serif !important;
  }
}

.buttonRegister {
  background-color: #c4c4c4;
  color: #ffffff;
  width: 200px / 750px * 100%;
  height: 48px;
  font-size: 18px;
  border-radius: 4px;
  border: none;
  margin-bottom: 50px;
}

.info {
  font-size: 18px;
  font-family: "Roboto", sans-serif !important;
  @media screen and (max-width: 430px) {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  a {
    text-decoration: none;
    color: $prime-blue;
  }
}

.calendarWrapper {
  display: flex;
  align-items: center;
  margin-top: 8px;
  img {
    width: 28px;
    height: 28px;
    opacity: 0;
    margin-left: 2.5%;
    @media screen and (max-width: 430px) {
      width: 14px;
      height: 14px;
      margin-left: 1%;
    }
  }
}

.smallInput {
  display: flex;
  align-items: center;
  margin-top: 8px;
  @media screen and (max-width: 430px) {
    margin-top: 0;
  }
  img {
    width: 28px;
    height: 28px;
    margin-left: 1%;
    opacity: 0;
    @media screen and (max-width: 430px) {
      width: 14px;
      height: 14px;
      margin-left: 1%;
    }
  }
}

.visibleImg {
  opacity: 1 !important;
}

.errorInput {
  border: 2px solid $red !important;
  color: $red;
}

.successInput {
  border: none;
  color: black;
}

.confirmPasswordActive {
  border: 2px solid $prime-blue !important;
}

.wrapperSpanDiv {
  display: flex;
  width: 96%;
  min-height: 30px;
}

.spanError {
  color: $red;
}

.activeButton {
  background-color: $prime-blue;
  width: 200px / 750px * 100%;
  height: 48px;
  font-size: 18px;
  border-radius: 8px;
  border: none;
  margin-bottom: 50px;
}
.timeZoneImg {
  width: 28px;
  height: 28px;
  margin-left: auto;
}

.flexContainer {
  display: flex;
}

.wrapperCheckbox {
  display: flex;
  align-items: center;
  margin: 10px 0;
  @media screen and (max-width: 430px) {
    margin-top: 0;
    margin-bottom: 20px;
  }
  img {
    margin-right: 10px;
  }
  span {
    font-family: "Roboto", sans-serif !important;
  }
}

.inputAndErrorBlock {
  min-height: 100px;
  @media screen and (max-width: 430px) {
    min-height: 80px;
  }
}

.lastNameInput {
  display: flex;
  align-items: center;
  @media screen and (max-width: 430px) {
    width: 110%;
  }
  input {
    width: 100%;
    @media screen and (max-width: 430px) {
      margin-top: 10px !important;
      margin-bottom: 16px !important;
    }
  }
  img {
    width: 28px;
    height: 28px;
    margin-left: 0.5%;
    opacity: 0;
    @media screen and (max-width: 430px) {
      width: 14px;
      height: 14px;
      margin-left: 1%;
    }
  }
}

.wrapperLastName {
  min-height: 125px;
  margin-bottom: 20px;
  @media screen and (max-width: 430px) {
    min-height: 100px;
    margin-bottom: 0px;
  }
}

.blockDegree {
  width: 93%;
  @media screen and (max-width: 430px) {
    width: 100%;
  }
  select {
    width: 100%;
    margin-top: 8px;
    border: 2px solid $border-black;
    border-radius: 4px;
    height: 60px;
    font-size: 22px;
    font-family: "Roboto", sans-serif !important;
    @media screen and (max-width: 430px) {
      width: 100%;
      max-width: 325px;
      height: 54px;
      font-size: 18px;
    }
  }
  option {
    font-size: 22px;
    font-family: "Roboto", sans-serif !important;
  }
}

.threeBlocksWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 70px;
  @media screen and (max-width: 430px) {
    flex-direction: column;
    margin-bottom: 30px;

    input {
      margin-bottom: 16px !important;
    }
  }
}

.blockSocialNumberInput {
  width: 25%;
  @media screen and (max-width: 430px) {
    width: 110%;
    min-height: 100px;
  }
  input {
    width: 100%;
    @media screen and (max-width: 430px) {
      margin-top: 10px !important;
    }
  }
}

.blockPhoneInput {
  width: 30%;
  @media screen and (max-width: 430px) {
    width: 110%;
    min-height: 100px;
  }
  input {
    width: 100%;
    @media screen and (max-width: 430px) {
      margin-top: 10px !important;
    }
  }
}

.blockBirth .errorMessage {
  margin-top: 13px;
}

.blockZipCodeInput {
  width: 20%;
  @media screen and (max-width: 430px) {
    min-height: 90px;
    width: 110%;
    max-width: 360px;
  }
  input {
    width: 100%;
    @media screen and (max-width: 430px) {
      margin-top: 10px !important;
      width: 30%;
    }
  }
}

.containerCity {
  min-height: 105px;
  @media screen and (max-width: 430px) {
    min-height: 90px;
    width: 100%;
    max-width: 360px;
  }
  img {
    width: 28px;
    height: 28px;
    opacity: 0;
    margin-left: 1.5%;
    @media screen and (max-width: 430px) {
      width: 14px;
      height: 14px;
      margin-left: 1%;
    }
  }
}

.blockCity {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 430px) {
    margin-bottom: 23px;
  }
}

.blocksmallInputCity {
  width: 36%;
  @media screen and (max-width: 430px) {
    width: 100vw;
    display: flex;
    flex-direction: column;
    min-height: 100px;

    input {
      margin-top: 8px !important;
      margin-bottom: 17px !important;
    }
  }
  input {
    width: 100%;
  }
}

.wrapperTimeZone {
  margin-bottom: 60px;
  @media screen and (max-width: 430px) {
    margin-bottom: 20px;
  }
}

.errorMessage {
  color: $red;
  font-size: 14px;
  font-family: "Roboto", sans-serif !important;
  margin-top: 10px;
  padding: 0;
  @media screen and (max-width: 430px) {
    margin-top: 0 !important;
    margin-bottom: 10px;
  }
}

.message {
  font-size: 14px;
  padding-bottom: 16px;
}

.disable {
  border: 2px solid rgb(202, 202, 202) !important;
  background-color: rgb(230, 230, 230);
}

@media (max-width: 1350px) {
  .wrapperDate {
    display: block;
  }
}

.logo {
  margin-left: 70px / 1440px * 100%;
  margin-top: 40px;
}

.hr {
  display: none;
  @media screen and (max-width: 430px) {
    display: flex;
    border: 1.5px solid #d9d9d9;
    width: calc(100vw - 50px);
    margin: 0 auto;
    background-color: #d9d9d9;
  }
}

.difficultHr {
  display: none;
  @media screen and (max-width: 430px) {
    border: 1.5px solid #d9d9d9;
    background-color: #d9d9d9;
    display: flex;
    margin: 0;
  }
}
