$prime-blue: #2b4bf2;
$red: #d43333;
$border-black: black;

.emailWrapper {
  min-height: 180px;
  h3 {
    margin-left: 0px !important;
    font-size: 20px;
    font-weight: bold !important;
    font-family: "Roboto", sans-serif !important;
    margin-bottom: 0 !important;
    padding: 0;
    text-align: left;
  }
  button {
    height: 60px;
    border: 2px solid $border-black;
    border-radius: 0 4px 4px 0;
    margin: 0;
    padding: 0;
    width: 30%;
    background-color: #c4c4c4;
    color: white;
    font-size: 18px;
    @media screen and (max-width: 430px) {
      border-radius: 4px;
      width: 164px;
      height: 48px;
    }
  }
  @media screen and (max-width: 430px) {
    ::placeholder {font-size: 18px;}
  }
  p {
    font-size: 18px;
    font-weight: normal;
    font-family: "Roboto", sans-serif !important;
  }
  .buttonEmail {
    background-color: $prime-blue;
  }
  input {
    width: 69%;
    border-radius: 4px 0 0 4px !important;
    margin: 0 !important;
    padding: 0;
    font-size: 22px;
    font-family: "Roboto", sans-serif !important;
    line-height: 130%;
    border-right: none !important;
    text-indent: 10px;
    @media screen and (max-width: 430px) {
      width: 100%;
      max-width: 325px;
      border-right: 2px solid black !important;
      border-radius: 4px !important;
      margin-bottom: 23px !important;
      font-size: 18px;
    }
  }
  .inputButtonWrapper {
    width: 79%;
    display: flex;
    @media screen and (max-width: 430px) {
      flex-direction: column;
      width: 100%;
    }
  }
}

.confirmEmail {
  display: flex;
  align-items: center;
  img {
    width: 28px;
    height: 28px;
    margin-right: 1%;
    @media screen and (max-width: 430px){
      width: 18px;
      height: 18px;
    }
  }
}

.message__wrapper {
  margin-bottom: 22px;
}

.errorMessage {
  color: $red;
  font-size: 14px;
  font-family: "Roboto", sans-serif !important;
  margin-top: 10px;
  padding: 0;
}

.emailError {
  border: 2px solid $red !important;
  color: $red;
  border-right: none !important;
}

.emailFocus {
  border-right: none !important;
  border: 2px solid $prime-blue;
}

.wrapper {
  font-size: 22px !important;
  font-family: "Roboto", sans-serif !important;
  line-height: 130%;
  margin: 10px 0 44px;
}

.flexContainer {
  display: flex;
  .warning {
    width: 30%;
    margin-left: 30px;
    font-family: "Roboto", sans-serif !important;
    font-size: 18px;
    a {
      cursor: pointer;
    }
  }
}
