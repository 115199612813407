.react-datepicker__header {
  border: none;
  background-color: transparent;
}

.react-datepicker {
  border: none;
}

.calendar {
  width: 70%;
}

.react-datepicker__month {
  margin: 0;
  text-align: center;
}

.react-datepicker__month-container {
  width: 557px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  font-size: 25px !important;
  width: 74px !important;
  line-height: 80px !important;
  border-radius: 50% !important;
}

.react-datepicker__navigation-icon {
  height: 30px;
}

.react-datepicker__navigation {
  margin: 0 auto;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  width: 40%;
  font-size: 30px;
  margin: 0 auto;
  display: none;
}

.react-datepicker__navigation--next {
  right: 160px;
  margin-top: 13px;
  display: none;
}

.react-datepicker__navigation--previous {
  left: 160px;
  margin-top: 13px;
  display: none;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 25px;
}

.header img {
  width: 25px;
}

.react-datepicker__day-name,
.react-datepicker__day {
  text-transform: uppercase;
}

.react-datepicker__day {
  color: #2b4bf2 !important;
  font-weight: bold;
}

.react-datepicker__day--disabled {
  color: #ccc !important;
  font-weight: 500;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #2b4bf2 !important;
  color: white !important;
}

@media screen and (max-width: 948px) {
  .react-datepicker__month-container {
    width: 100% !important;
    max-width: 554px !important;
  } 
}

@media screen and (max-width: 600px) {
  .header {
    font-size: 20px;
    margin-top: 13px;
  }

  .react-datepicker__month-container {
    width: 100% !important;
    max-width: 360px !important;
  } 

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    font-size: 16px !important;
    width: 46px !important;
    line-height: 46px !important;
    border-radius: 50% !important;
  }
}
