.footer {
  bottom: 0;
  width: 100%;
  background-color: #000000;
  p,
  a {
    font-family: "Roboto", sans-serif;
    margin: 0;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
  }
}

.footerWrapper {
  display: flex;
  height: 236px;
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;
  align-items: flex-start;
  justify-content: space-around;
  background-color: #000000;
  @media screen and (max-width: 1050px) {
    flex-direction: column;
    height: auto;
    margin: 0 auto;
    align-items: center;
  }
  @media screen and (max-width: 600px) {
    display: none;
  }
  .pages__wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }
}

.first__section {
  display: flex;
  width: 100%;
  max-width: 700px;
  justify-content: space-around;
}
.second__section {
  display: flex;
  width: 100%;
  max-width: 800px;
  justify-content: space-around;
}

.navigationWrapper {
  width: 170px;
  display: flex;
  flex-direction: column;
}
.document__wrapper {
  display: flex;
  flex-direction: column;
}

.questions__wrapper {
  display: flex;
  flex-direction: column;
  .questions__block {
    width: 200px;
    display: flex;
    flex-direction: column;
  }
  .questions__text {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.02em;
    font-feature-settings: "salt" on, "ss01" on, "ss02" on, "ss03" on;
  }
}

.navigationTitle,
.contactTitle {
  margin-top: 37px !important;
  margin-bottom: 22px !important;
  font-size: 18px !important;
  line-height: 25px !important;
}

.legal__document {
  width: 200px;
  display: flex;
  flex-direction: column;
  // margin-left: 125px;
  // margin-right: 62px;
  p {
    color: white;
  }
}

.speaksWrapper {
  display: flex;
  margin-top: 38px;
  p {
    color: white;
  }
}

.speaksImg {
  display: flex;
  flex-direction: column;
  .support__block {
    display: flex;
    flex-direction: row;
    width: 221px;
    align-items: center;
    margin-bottom: 13.5px;
  }
  .img_AS {
    // margin-bottom: 13px;
    margin-right: 10px;
    width: 50px;
    height: 50px;
  }
  .img_AWS {
    // margin-bottom: 14px;
    margin-right: 15px;

    width: 40px;
    height: 40px;
  }
  .img_NSF {
    margin-right: 12px;
    width: 45px;
    height: 45.19px;
  }
}

.footerWrapper__mobile {
  display: none;

  p {
    color: black;
  }
  a {
    color: #00a3ff;
  }

  .hr {
    border: 1.5px solid #d9d9d9;
    width: calc(100vw - 50px);
    margin: 0 auto;
    background-color: #d9d9d9;
  }

  @media screen and (max-width: 600px) {
    background-color: white;
    padding-top: 50px;
    padding-bottom: 42px;
    display: flex;
    flex-direction: column;

    .speaksWrapper {
      margin: 0 auto;
      width: 100%;
      max-width: 290px;
      margin-bottom: 24px;
    }

    .document__wrapper {
      margin: 0 auto;
      width: 100%;
      max-width: 290px;
      margin-bottom: 28px;

      .legal__document {
        width: 290px;
        margin-left: 0;
      }

      .navigationTitle {
        margin-top: 27px;
        font-weight: 700;
        font-size: 18px;
        line-height: 140%;
      }
    }

    .questions__wrapper {
      width: 100%;
      max-width: 290px;
      margin: 0 auto;

      .questions__text {
        width: 290px;
      }

      .navigationTitle {
        margin-top: 28px;
        font-weight: 700;
        font-size: 18px;
        line-height: 140%;
      }
    }
  }
}
