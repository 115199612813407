$primeblue: #2b4bf2;

.wrapper {
  margin-top: 33px;
  @media screen and (max-width: 1160px) {
    margin-left: 33px;
  }
  @media screen and (max-width: 948px) {
    margin-left: 0;
    margin-top: 0;
  }
  @media screen and (max-width: 430px) {
    margin-top: 2px;
  }
  button {
    background-color: $primeblue;
    margin-top: 15px;
    @media screen and (max-width: 430px) {
      margin-bottom: 22px;
    }
  }
  p {
    margin: 5px 0;
    font-size: 18px;
    font-family: "Roboto", sans-serif;
  }
  .price {
    font-weight: bold;
    font-size: 20px;
    line-height: 130%;
  }

  @media screen and (max-width: 948px) {
    display: flex;
    flex-direction: column;

    a {
      display: none;
    }
  }
}

a {
  font-size: 18px;
  color: $primeblue;
}

.imgDetailInfo {
  margin: 0 auto !important;
  margin-top: 35px !important;
  display: block !important;
  width: 200px !important;
  height: 200px !important;
}
.disabled {
  background-color: #d5d5d5 !important;
}
